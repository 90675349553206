import { Route } from '@angular/router';

export const ITEMS_ROUTES: Route[] = [
    {
        path: 'stores',
        title: 'Сводка по аптекам',
        data: {
            isNav: true,
            navTitle: 'Аптеки',
            permissionId: 'items',
        },
        loadComponent: () =>
            import('./stores/stores.component').then((m) => m.StoresComponent),
    },
    {
        path: 'prices',
        title: 'Цены и остатки',
        data: {
            isNav: true,
            navTitle: 'Цены и остатки',
            permissionId: 'items',
        },
        loadComponent: () =>
            import('./prices/prices.component').then((m) => m.PricesComponent),
    },
    {
        path: 'unconnected',
        title: 'Несвязанные товары',
        data: {
            isNav: true,
            navTitle: 'Несвязанные товары',
            permissionId: 'items',
        },
        loadComponent: () =>
            import('./unconnected/unconnected.component').then(
                (m) => m.UnconnectedComponent
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/items/stores',
    },
];
